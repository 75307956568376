/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
.itb-container .itb-figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .itb-container .itb-figure picture a {
    display: block; }
  .itb-container .itb-figure .first {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .itb-container .itb-figure .second {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }

.itb-container .itb-text {
  text-align: center;
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-line-pack: justify;
      align-content: space-between; }

.itb-container .heading-text {
  width: 100%; }

@media (min-width: 544px) {
  .itb-container.horizontal .itb-figure {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .itb-container.horizontal .itb-figure picture {
      width: 50%; }
    .itb-container.horizontal .itb-figure figcaption {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 50%; } }

.itb-container.horizontal .itb-text {
  padding: 2rem 3rem; }
  @media (min-width: 544px) {
    .itb-container.horizontal .itb-text.text-left {
      padding: 2rem 7rem 2rem 3rem; }
    .itb-container.horizontal .itb-text.text-right {
      padding: 2rem 3rem 2rem 7rem; }
    .itb-container.horizontal .itb-text.text-center {
      padding: 2rem 5rem; } }

@media (min-width: 769px) {
  .itb-container.text-slider .itb-figure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .itb-container.text-slider .itb-figure picture {
      width: 50%; }
    .itb-container.text-slider .itb-figure figcaption {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 50%; }
    .itb-container.text-slider .itb-figure .first {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; }
    .itb-container.text-slider .itb-figure .second {
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2; } }

.itb-container.light .itb-figure {
  background-color: var(--skin-bg-dark); }

.itb-container.light .itb-text h1,
.itb-container.light .itb-text h2,
.itb-container.light .itb-text h3,
.itb-container.light .itb-text h4,
.itb-container.light .itb-text p {
  color: var(--color-white); }

.itb-container.dark .itb-figure {
  background-color: var(--color-white); }

.itb-container.dark .itb-text h1,
.itb-container.dark .itb-text h2,
.itb-container.dark .itb-text h3,
.itb-container.dark .itb-text h4,
.itb-container.dark .itb-text p {
  color: var(--skin-bg-dark); }

.itb-container.no-bg .itb-figure {
  background-color: transparent; }
